

































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ConfirmDialog extends Vue {
  @Prop()
  private readonly value!: unknown

  @Prop({ required: true })
  private readonly title!: string

  @Prop({ default: '' })
  private readonly message!: string
}
